import { useUtilityStore } from "~/store/UtililityStore";

export const useUtils = () => {
  const utilityStore = useUtilityStore();
  const selectedLocale = computed(() => utilityStore.selectedLocale);

  const showDecimalValueInLocale = computed(() => {
    return (v) => {
      if (v) {
        try {
          return Number(v).toFixed(2).toLocaleString(selectedLocale.value);
        } catch (error) {
          return v;
        }
      }
      return "";
    };
  });

  
  return { showDecimalValueInLocale };
};
