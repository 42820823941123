<template>
  <div class="Product-box-items" :id="`Product-box-items-${item.Identifier}`"
    :key="`Product-box-items-${item.Identifier}`">
    <div class="lable-container lable-container-main" v-if="item?.statusPreorder">
      <span class="lable1-pre">{{ $t("preorder") }}</span>
      <span class="lable2-pre">{{ formatDate(item.releaseDate) }}</span>
    </div>
    <div class="lable-container lable-container-promo  lable-container-main" v-if="item?.isPromo">
      <span class="lable1-promo"> {{ $t("promo") }}</span>
      <span class="lable2-promo">{{ "Hurry up!" }}</span>
    </div>

    <div class="flag-container" v-if="item.vgMenuLanguages.length">
      <div v-for="lng in item.vgMenuLanguages">
        <img height="20" :src="getLangImgUrl(lng)" :id="item.Identifier" :alt="lng" :key="lng"
          onerror="this.style.display='none'" />
      </div>
    </div>
    <div class="img-wrapper">
      <div class="front">
        <NuxtLinkLocale :to="{
          path:
            '/products/' +
            item.Identifier +
            '/' +
            productLinkTitle(item.name).replaceAll(/[^a-zA-Z0-9]+/g, '-').toLowerCase(),
        }">
          <img v-if="item.images[0]" :src="getImgUrl(item.images[0])" :id="item.Identifier" class="product-search-img"
            :alt="item.name['en_GB']" key="1img" />
          <img v-else
            src="https://assets.cld.be/cdn-cgi/image/width=200,quality=40/9/3/1/0/931054989870582dda8788cf81759668c311beee_Image_Coming_Soon.png"
            :id="item.Identifier" class="product-search-img" :alt="item.name['en_GB']" />
        </NuxtLinkLocale>
      </div>



      <div class="cart-info cart-wrap">
        <button :title="$t('cart.quick_view')" @click="showQuickModal(item)" variant="primary">
          <i class="ti-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <NuxtLinkLocale :to="{
      path:
        '/products/' +
        item.Identifier +
        '/' +
        productLinkTitle(item.name).replaceAll(/[^a-zA-Z0-9]+/g, '-').toLowerCase(),
    }">
      <div class="product-detail">
        <h6 class="item-name">
          <v-tooltip v-if="pageReady" :key="item.Identifier" :attach="`Product-box-items-${item.Identifier}`"
            close-delay="1000" activator="parent" location="top">
            {{ productTitle(item.name) }}
          </v-tooltip>
          {{ productTitle(item.name) }}
        </h6>

        <!-- TODO UI -->
        <div class="d-flex flex-wrap m-auto">
          <div class="item-id" style="margin-right: 5px;">ID: {{ item.Identifier }}</div>
          <div class="item-ean"> EAN: {{ item.ean }} </div>
        </div>

        <div class="d-flex flex-wrap m-auto">{{ $t('brand') }}: {{ item.brandText }}</div>

        <div class="d-flex flex-wrap m-auto">
          <div class="item-price">
            {{ $t('stock') }}: {{ item.stock }}
            <span class="badge badge-pill badge-primary" v-if="item.stock >= 30">
              30+
            </span>
            <span class="badge badge-pill badge-primary" v-if="item.stock > 0 && item.stock < 30">
              {{ item.stock }}
            </span>
            <span class="badge badge-pill badge-primary" v-if="item.stock <= 0">
              {{ item.stock }}
            </span>
          </div>


        </div>





        <div class="d-flex flex-wrap m-auto">
          <div class="item-price" v-if="item.price">
            {{ $t('price') }} {{ showDecimalValueInLocale(item.price) }}
          </div>
        </div>

        <div class="d-flex flex-wrap m-auto">
          <div class="item-suggested-price">
            {{ $t('products.retail_price') }}:
            {{ showDecimalValueInLocale(item.dvdSuggestedPriceAmount) }}
          </div>
        </div>
        <div class="d-flex flex-wrap m-auto">
          <div class="item-price">
            <span class="badge badge-success" v-if="item.refCli">
              RefCli: {{ item.refCli }}
            </span>
            <span class="badge badge-danger" v-if="bo">BO: {{ bo }} </span>
          </div>
        </div>
      </div>
    </NuxtLinkLocale>
    <client-only>
      <OnClickOutside @trigger="close" v-if="showQuickView.show">
        <WidgetsQuickview :productData="showQuickView.item" :isAuthenticated="isAuthenticated" />
      </OnClickOutside>
    </client-only>
    <WidgetsLoader :loading="pending">
      <div class="product-buttons" v-if="isAuthenticated">
        <button @click="addToCart()" class="btn btn-solid">
          {{ $t('products.add_to_cart') }} <i class="ti-shopping-cart"></i>
        </button>
      </div>
    </WidgetsLoader>
  </div>
</template>

<script setup>
import { useUserStore } from "~~/store/UserStore";
import { useCartStore } from "~~/store/CartStore";
import { useUtilityStore } from "~/store/UtililityStore";
import { trackAddToCart, mapProduct } from "~/composables/gtmEvents";
import { useFilterStore } from "~/store/FilterStore";
import { OnClickOutside } from "@vueuse/components/index";
import { useI18n } from "vue-i18n";

const userStore = useUserStore();
const cartStore = useCartStore();
const utilityStore = useUtilityStore();
const filterStore = useFilterStore();

const cldUser = computed(() => userStore.cldUser);
const selectedLanguage = computed(() => utilityStore.selectedLanguage);
const selectedLocale = computed(() => utilityStore.selectedLocale);
const isAuthenticated = computed(() => userStore.isAuthenticated);
const showQuickView = computed(() => filterStore.showQuickView)
const props = defineProps(["index", "item", "bo"]);
const imageSrc = ref("");
const { t } = useI18n();
const pageReady = ref(false);

const pending = ref(false);
// const emit = defineEmits();
const close = () => {
  filterStore.setShowQuickView({ show: false, item: {} })
};
const { showDecimalValueInLocale } = useUtils();
// Methods
// const showDecimalValueInLocale = computed(() => {
//   return (v) => {
//     if (v) {
//       try {
//         return Number(v).toFixed(2).toLocaleString(selectedLocale);
//       } catch (error) {
//         return v;
//       }
//     }
//     return "";
//   };
// });
const productTitle = computed(() => (name) => name[selectedLanguage.value] || name['fr_BE'] || name['en_GB'] || name)

const productLinkTitle = computed(() => (name) => name[selectedLanguage.value] || name['en_GB'] || name['fr_BE'] || name)


const getImgUrl = (path) =>
  "https://assets.cld.be/cdn-cgi/image/format=auto,width=200,height=200,quality=75,fit=pad,background=%23FFFFFF/" + path;

const getLangImgUrl = (path) =>
  `https://unpkg.com/language-icons/icons/${path.toLocaleLowerCase()}.svg`;

const gtmAddToCart = () => {
  const mappedItem = mapProduct([props.item]);
  trackAddToCart({
    items: mappedItem,
    customer_id: cldUser.value.Id,
    value: props.item["dvdSuggestedPriceAmount"],
  });
};

const addToCart = async () => {
  pending.value = true;
  const { added, status } = await cartStore.addToCart({
    sku: props.item.Identifier,
    qty: 1,
  });

  if (status) {
    //show notification
    useNuxtApp().$showToast({
      msg: t('alerts.added_to_cart.desc'),
      type: "success",
    });
    gtmAddToCart();
  } else {
    useNuxtApp().$showToast({
      msg: t('alerts.failed_add_cart.desc'),
      type: "error",
    });
  }
  pending.value = false;
};

const showQuickModal = (item) => {
  filterStore.setShowQuickView({ show: true, item })
};
const formatDate = computed(() => (date) => {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
});
// const imgChange = (imgsrc) => {
//   imageSrc.value = imgsrc;
// };
onMounted(() => {

  setTimeout(() => {
    pageReady.value = true;
  }, 1000);
})
// onNuxtReady(() => {

// });
</script>

<style scoped>
.img-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  max-height: 240px;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: auto;
}





/* .product-detail {
  width: auto;
  height: 140px;
  font-weight: 400;
  color: #777777;
  line-height: 18px;
} */

.item-name {
  color: #4e4d4d;
}

/* .item-id {
  color: var(--theme-deafult);
}
.item-brand {
  color: var(--theme-deafult);

}
.item-ean{
  color: #05780b;

}
.item-suggested-price{
  color: #05780b;
        
} */

/* .item-id {
  color: var(--theme-deafult);
}
.item-brand {
  color: var(--theme-deafult);

}
.item-ean{
  color: #05780b;

}
.item-suggested-price{
  color: #05780b;
        
} */

.flag-container {
  position: absolute;
  z-index: 10;
  bottom: 0;
  top: 45px;
  right: 0;
  height: 24px;
  max-width: 60px;
  height: fit-content;
  line-height: 18px;
  display: flex;
  flex-direction: column;
}

/* Preorder lable */
.lable-container {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: -6px;
  top: -3px;
  height: 24px;
  max-width: 60px;
  height: auto;
  line-height: 18px;
  display: flex;
  flex-direction: column;
}



.lable-container-main::after {
  position: absolute;
  border-style: solid;
  top: 53px;
  bottom: auto;
  left: 0;
  content: "";
  border-width: 6px 0 0 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 0;
}

.lable-container>span {
  display: block;
  width: 56px;
  height: auto;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 4px 6px 4px 2px;
}

.lable-container::after {
  border-color: var(--theme-deafult) transparent transparent transparent;
}
.lable-container-promo::after {
  border-color: rgb(188, 6, 6) transparent transparent transparent;
}

/* Promo lable */


.lable-container-main-promo::after {
  position: absolute;
  border-style: solid;
  top: 50px;
  bottom: auto;
  left: 0;
  content: "";
  border-width: 6px 0 0 6px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 0;
}

.lable-container-promo>span {
  display: block;
  width: 56px;
  height: auto;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.5px;
  text-align: center;
  padding: 4px 6px 4px 2px;
}


.lable-container-promo::after {
  border-color: rgb(188, 6, 6) transparent transparent transparent;
}



.lable1-pre {
  background: var(--theme-deafult);
  color: #ffffff;
}


.lable2-pre {
  color: var(--theme-deafult);
  background: #ffffff;
  border: 1px solid var(--theme-deafult);
  text-align: center;
}


.lable1-promo {
  background: rgb(188, 6, 6);
  color: #ffffff;
}

.lable2-promo {
  color: rgb(188, 6, 6);
  background: #ffffff;
  border: 1px solid rgb(188, 6, 6);
  text-align: center;
}
</style>
